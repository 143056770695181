import { Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import CustomerData from '../../components/customer-info/customer-data';
import OrderCustomerModal from '../../components/order-customer-container/order-customer-modal';
import useDebounce from '../../hooks/use-debounce';
import { CustomerInterface } from '../../interfaces';
import CustomerClient from '../../lib/customer-client';

const filter = createFilterOptions<CustomerInterface>();

interface CustomerFormInterface extends CustomerInterface {
  inputValue?: string;
}

const useStyles = makeStyles({
  label: {
    margin: '25px 25px 5px 25px',
    fontWeight: 'bold',
    float: 'left',
    width: '150px',
  },
  inputField: { margin: '0px 25px 5px 25px' },
  customerSearch: { width: '400px', float: 'left' },
  customerSearchID: { width: '250px', float: 'left' },
  customerSearchContainer: {
    width: '750px',
    float: 'left',
  },
  grid: {
    'grid-auto-flow': 'row',
    justify: 'flex-start',
    alignItems: 'flex-start',
  },
  error: {
    color: 'red',
    marginLeft: '225px',
    marginBottom: '5px',
  },
});

type Props = {
  formik: any;
  customer?: CustomerInterface | null;
  validationSchema: any;
  campaignData: any;
  setCustomerName: Function;
  setCustomerBusinessId: Function;
  customerName: string;
  setCustomer: Function;
  businessId: string | null;
};

const OrderCustomerSearchContainer: React.FC<Props> = ({
  validationSchema,
  formik,
  customer,
  campaignData,
  setCustomerName,
  setCustomerBusinessId,
  customerName,
  setCustomer,
  businessId,
}: Props) => {
  const [customers, setCustomers] = useState<any>([]);
  const debouncedSearchTerm = useDebounce(customerName, 400);

  const classes = useStyles();

  const getCustomersVat = async (businessid: string, initData?: string): Promise<void> => {
    try {
      setCustomerBusinessId(businessid);
      if (businessid.length > 8) {
        const result = await CustomerClient.findCustomersByBusinessid(businessid);
        const customersObject = result.map((customer) => {
          return customer;
        });
        setCustomers(customersObject);
        const singleCustomer: CustomerInterface | undefined = initData
          ? customersObject.find((c) => c && c.id === initData)
          : customersObject[0];
        setCustomer(singleCustomer);
        setCustomerBusinessId(singleCustomer?.businessid || '');
        if (singleCustomer && singleCustomer.customerName) {
          formik.setFieldValue('customerName', singleCustomer.customerName);
          formik.setFieldValue('customerId', singleCustomer.companyId);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (campaignData?.customer && campaignData.customer.businessid) {
      getCustomersVat(campaignData?.customer.businessid, campaignData?.customer.id);
    }
  }, []);

  const getCustomers = async (): Promise<void> => {
    try {
      const result = await CustomerClient.findCustomersByName(debouncedSearchTerm);

      const customersObject = result.map((customer) => {
        return customer;
      });
      setCustomers(customersObject);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      getCustomers();
    }
  }, [debouncedSearchTerm]);

  // Workaround for customerField. Not working with validateSignleField.
  const validateCustomer = (): void => {
    formik.registerField('customerName', { validationSchema });
    formik.validateField('customerName');
  };

  return (
    <>
      <Grid item container className={classes.grid}>
        <Grid item>
          <Typography className={classes.label}>Asiakas</Typography>
        </Grid>
        <Grid
          item
          container
          className={(classes.grid, classes.customerSearchContainer)}
          spacing={4}
        >
          <Grid item>
            <Autocomplete
              value={customer}
              className={(classes.inputField, classes.customerSearch)}
              id="customerName"
              noOptionsText="Hakutuloksia ei löytynyt"
              onBlur={validateCustomer}
              onChange={(
                event: React.FormEvent<EventTarget>,
                newValue: CustomerFormInterface | null
              ): void => {
                if (newValue && newValue.customerName) {
                  setCustomer(newValue);
                  setCustomerBusinessId(newValue.businessid);
                  formik.setFieldValue('customerName', newValue.customerName);
                  formik.setFieldValue('customerId', newValue.companyId);
                } else {
                  setCustomer(null);
                  setCustomerBusinessId('');
                  formik.setFieldValue('customerName', '');
                  formik.setFieldValue('customerId', '');
                }
              }}
              filterOptions={(options, params): CustomerFormInterface[] => {
                const filtered = filter(options, params) as CustomerFormInterface[];
                return filtered;
              }}
              handleHomeEndKeys
              options={customers as CustomerFormInterface[]}
              getOptionLabel={(option: any): string => {
                if (typeof option === 'string') {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.customerName;
              }}
              renderOption={(option): string => option.customerName}
              renderInput={(params): JSX.Element => (
                <TextField
                  {...params}
                  name="customerName"
                  label="Asiakkaan nimi"
                  margin="normal"
                  variant="outlined"
                  onChange={(event): void => setCustomerName(event.target.value)}
                />
              )}
            />
          </Grid>
          <Grid item>
            <TextField
              id="businessid"
              className={(classes.inputField, classes.customerSearchID)}
              value={businessId || ''}
              label="Y-tunnus"
              margin="normal"
              variant="outlined"
              onChange={(event): Promise<void> => getCustomersVat(event.target.value)}
            />
          </Grid>
          <OrderCustomerModal title="lisää asiakas" getCustomersVat={getCustomersVat} />
          {customer && customer.companyId.length > 2 && (
            <OrderCustomerModal
              title="muokkaa asiakasta"
              getCustomersVat={getCustomersVat}
              customer={customer}
              editMode={true}
            />
          )}
        </Grid>
      </Grid>
      <Grid className={classes.error}>
        {formik.errors.customerName
          ? formik.errors.customerName
          : formik.errors.customerId
          ? formik.errors.customerId
          : null}
      </Grid>
      <Grid item container className={classes.grid}>
        <Grid item className={classes.label}></Grid>
        <CustomerData customer={customer} />
      </Grid>
    </>
  );
};

export default OrderCustomerSearchContainer;
