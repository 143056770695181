import {
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useAddOrderDispatch, useAddOrderState } from '../../context/add-order-context';
import { useOrderFormState } from '../../context/order-form-context';

const useStyles = makeStyles(() => ({
  inputContainer: {
    justifyContent: 'space-between',
  },
  rightItem: {
    width: '400px',
  },
  boldText: {
    fontWeight: 'bold',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: 'auto 0px',
  },
  blueText: { fontSize: '16px', color: '#5E8EFC' },
}));

const SummaryTapePrice: React.FC = () => {
  const classes = useStyles();
  const addOrder = useAddOrderState();
  const addOrderDispatch = useAddOrderDispatch();

  const orderFormState = useOrderFormState();

  const { price } = orderFormState;
  const { tapePrice } = addOrder;

  const addTapePrice = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    addOrderDispatch({ type: 'update', payload: { tapePrice: +value } });
  };

  return (
    <Grid
      container
      className={classes.inputContainer}
      alignItems="flex-end"
      item
      style={{ marginBottom: '35px' }}
    >
      <Grid item>
        <Typography className={classes.boldText}>Tuotanto</Typography>
      </Grid>
      <Grid item container direction="row" className={classes.rightItem}>
        <TextField
          name="tapePrice"
          onChange={addTapePrice}
          value={tapePrice}
          onFocus={(event): void => event.target.select()}
          type="number"
          inputProps={{
            min: 0.0,
          }}
          InputProps={{ className: classes.title }}
        ></TextField>
      </Grid>
    </Grid>
  );
};

export default SummaryTapePrice;
