import { CampaignPriceRow, MultipressCalculatedPrice } from '../../interfaces';
import { OrderBatch } from '../../types/order-batch';
import productClient from '../product-client';

export interface Order {
  productId: string;
  discountValue: number;
  isPercentage: boolean;
  vat: number;
  customerDiscount: number;
}

interface Batch {
  variations: number;
  quantity: number;
  weeks: number;
  dates: number;
  months: number;
  batches: OrderBatch[];
  percentage?: number;
  name?: string;
}

interface BatchPrice {
  batch: Batch;
  price: MultipressCalculatedPrice;
}

const round = (value: number): number => {
  return Math.round(value * 1000) / 1000;
};

const reduceBatchPrices = (batchPrices: BatchPrice[], solid = false): MultipressCalculatedPrice => {
  return batchPrices
    .map((p) => p.price)
    .reduce((value: MultipressCalculatedPrice, current: MultipressCalculatedPrice) => {
      return {
        discount: round(value.discount + current.discount),
        listPrice: round(value.listPrice + current.listPrice),
        priceRows: value.priceRows.concat(current.priceRows),
        solid,
        totalPrice: round(value.totalPrice + current.totalPrice),
        vat: round(value.vat + current.vat),
        mediaDiscount: round(value.mediaDiscount + current.mediaDiscount),
      };
    });
};

const handleRows = (batchPrices: BatchPrice[]): CampaignPriceRow[] => {
  const priceRows: CampaignPriceRow[] = [];
  let tapePriceRow: CampaignPriceRow | null = null;
  batchPrices.forEach((priceItem: { price: MultipressCalculatedPrice; batch: Batch }) => {
    priceItem.batch.batches.map((batch: OrderBatch): void => {
      const price = priceItem.price;
      let singleRow = {
        basePrice: 0,
        discountPercentage: 0,
        productId: '',
        productName: '',
        quantity: 0,
        unitPrice: 0,
      };

      price.priceRows.forEach((row) => {
        // If this is the "Teippauksen hinta" row, handle it separately
        if (row.productName === 'Teippauksen hinta' || row.productName === 'Tuotanto') {
          tapePriceRow = {
            basePrice: row.basePrice,
            discountPercentage: row.discountPercentage || 0,
            productId: row.productId,
            productName: row.productName,
            quantity: row.quantity,
            unitPrice: row.unitPrice,
            customName: false,
            batch: batch.id.toString(),
          };
        } else {
          // Otherwise, reduce other rows
          singleRow.basePrice += row.basePrice;
          singleRow.discountPercentage = row.discountPercentage || 0;
          singleRow.productId = row.productId;
          singleRow.productName = row.productName;
          singleRow.quantity = row.quantity;
          singleRow.unitPrice += row.unitPrice;
        }
      });
      const period = priceItem.batch.dates || priceItem.batch.months || priceItem.batch.weeks;
      const batchPeriod = batch.unitAmount;
      const row = {
        basePrice: round(singleRow.basePrice * (batchPeriod / period)),
        discountPercentage: singleRow.discountPercentage,
        productId: singleRow.productId,
        quantity: singleRow.quantity,
        unitPrice: round(singleRow.unitPrice * (batchPeriod / period)),
        productName: `${batch.name}`,
        customName: batch.customName,
        batch: `${batch.id}`,
      };
      priceRows.push(row);
    });
  });
  if (tapePriceRow) {
    priceRows.push(tapePriceRow);
  }
  return priceRows;
};

const handleBatches = (batches: OrderBatch[]): Batch[] => {
  const bats = batches.map((batch) => ({
    variations: batch.variations.length,
    quantity: batch.quantity,
    dates: batch.dates ? batch.unitAmount : 0,
    months: batch.months ? batch.unitAmount : 0,
    weeks: batch.weeks ? batch.unitAmount : 0,
    batches: [batch],
    name: batch.name,
  }));
  const realresults: Batch[] = [];
  bats.forEach((bat) => {
    const res = realresults.find(
      (ra) => ra.variations === bat.variations && ra.quantity === bat.quantity
    );
    if (res) {
      res.dates += bat.dates;
      res.weeks += bat.weeks;
      res.months += bat.months;
      res.batches = res.batches.concat(bat.batches);
    } else realresults.push(bat);
  });
  return realresults;
};

const handleMonthPrice = async (
  order: Order,
  customPrice = false,
  customListPrice = 0,
  orderBatches: OrderBatch[],
  mediaDiscount: boolean,
  floatingOrder?: {
    floating: boolean;
    quantity: number;
    variations: string[];
    floatingAmount: number;
    batchName: string;
  },
  tapePrice = 0
): Promise<MultipressCalculatedPrice> => {
  //pass
  if (floatingOrder && floatingOrder.floating) {
    if (customPrice) {
      const price = await productClient.calculateFromSolidPrice(
        {
          ...order,
          rowName: floatingOrder.batchName,
          units: floatingOrder.variations.length,
          variations: floatingOrder.variations.length,
          quantity: floatingOrder.floatingAmount,
          mediaDiscount,
          tapePrice,
        },
        customListPrice
      );
      price.priceRows = [
        price.priceRows.reduce((result, row) => {
          return {
            basePrice: result.basePrice + row.basePrice,
            discountPercentage: row.discountPercentage,
            productId: row.productId,
            productName: row.productName,
            quantity: row.quantity,
            unitPrice: row.unitPrice + result.unitPrice,
          };
        }),
      ];
      return price;
    }

    const price = await productClient.calculateMultipressPrice({
      ...order,
      rowName: floatingOrder.batchName,
      units: floatingOrder.variations.length,
      variations: floatingOrder.variations.length,
      quantity: floatingOrder.floatingAmount,
      mediaDiscount,
      tapePrice,
    });

    price.priceRows = [
      price.priceRows.reduce((result, row) => {
        return {
          basePrice: result.basePrice + row.basePrice,
          discountPercentage: row.discountPercentage,
          productId: row.productId,
          productName: row.productName,
          quantity: row.quantity,
          unitPrice: row.unitPrice + result.unitPrice,
        };
      }),
    ];
    return price;
  }

  //undefined kun batches ei oo kunnossa
  const handledBatches = handleBatches(orderBatches);
  if (customPrice) {
    const batchValues = handledBatches.reduce((value: number, current: Batch) => {
      return value + current.quantity * current.dates * current.variations;
    }, 0);
    // tassa jaetaan tuo nuiden batsien kesken
    handledBatches.forEach((batch) => {
      batch.percentage = (batch.quantity * batch.dates * batch.variations) / batchValues;
    });

    const promises = handledBatches.map(async (batch) => {
      const price = await productClient.calculateFromSolidPrice(
        {
          ...order,
          rowName: batch.name || '',
          units: batch.variations,
          variations: batch.variations,
          quantity: batch.months,
          tapePrice,
        },
        customListPrice * (batch.percentage || 1)
      );
      return { batch, price };
    });

    const prices: BatchPrice[] = await Promise.all(promises);
    /// rakennetaan hinta objekti

    const price = reduceBatchPrices(prices, customPrice);
    const rows = handleRows(prices);
    price.priceRows = rows;
    return price;
  }

  const batchMultipressPrice = handledBatches.map(async (batch) => {
    const price = await productClient.calculateMultipressPrice({
      ...order,
      rowName: batch.name || '',
      units: batch.variations,
      variations: batch.variations,
      quantity: batch.months,
      tapePrice,
    });
    return { batch, price };
  });

  const batchPrices = await Promise.all(batchMultipressPrice);

  const price = reduceBatchPrices(batchPrices, customPrice);
  price.priceRows = handleRows(batchPrices);
  return price;
};

const handleWeekPrice = async (
  order: Order,
  customPrice = false,
  customListPrice = 0,
  orderBatches: OrderBatch[],
  mediaDiscount: boolean,
  floatingOrder?: {
    floating: boolean;
    quantity: number;
    variations: string[];
    floatingAmount: number;
    batchName: string;
  }
): Promise<MultipressCalculatedPrice> => {
  if (floatingOrder && floatingOrder.floating) {
    if (customPrice) {
      const price = await productClient.calculateFromSolidPrice(
        {
          ...order,
          rowName: floatingOrder.batchName,
          units: floatingOrder.floatingAmount,
          variations: floatingOrder.variations.length,
          quantity: floatingOrder.quantity,
          mediaDiscount,
        },
        customListPrice
      );
      price.priceRows = [
        price.priceRows.reduce((result, row) => {
          return {
            basePrice: result.basePrice + row.basePrice,
            discountPercentage: row.discountPercentage,
            productId: row.productId,
            productName: row.productName,
            quantity: row.quantity,
            unitPrice: row.unitPrice + result.unitPrice,
          };
        }),
      ];
      return price;
    }
    const price = await productClient.calculateMultipressPrice({
      ...order,
      rowName: floatingOrder.batchName,
      units: floatingOrder.floatingAmount,
      variations: floatingOrder.variations.length,
      quantity: floatingOrder.quantity,
      mediaDiscount,
    });

    price.priceRows = [
      price.priceRows.reduce((result, row) => {
        return {
          basePrice: result.basePrice + row.basePrice,
          discountPercentage: row.discountPercentage,
          productId: row.productId,
          productName: row.productName,
          quantity: row.quantity,
          unitPrice: row.unitPrice + result.unitPrice,
        };
      }),
    ];
    return price;
  }

  const handledBatches = handleBatches(orderBatches);

  if (customPrice) {
    const batchValues = handledBatches.reduce((value: number, current: Batch) => {
      return value + current.quantity * current.weeks * current.variations;
    }, 0);
    // tassa jaetaan tuo nuiden batsien kesken
    handledBatches.forEach((batch) => {
      batch.percentage = (batch.quantity * batch.weeks * batch.variations) / batchValues;
    });

    const promises = handledBatches.map(async (batch) => {
      const price = await productClient.calculateFromSolidPrice(
        {
          ...order,
          rowName: batch.name || '',
          units: batch.weeks,
          variations: batch.variations,
          quantity: batch.quantity,
          mediaDiscount,
        },
        customListPrice * (batch.percentage || 1)
      );
      return { batch, price };
    });

    const prices: BatchPrice[] = await Promise.all(promises);
    /// rakennetaan hinta objekti

    const price = reduceBatchPrices(prices);
    const rows = handleRows(prices);
    price.priceRows = rows;
    return price;
  }
  const batchMultipressPrice = handledBatches.map(async (batch) => {
    const price = await productClient.calculateMultipressPrice({
      ...order,
      rowName: batch.name || '',
      units: batch.weeks,
      variations: batch.variations,
      quantity: batch.quantity,
      mediaDiscount,
    });
    return { batch, price };
  });

  const batchPrices = await Promise.all(batchMultipressPrice);

  const price = reduceBatchPrices(batchPrices, customPrice);
  price.priceRows = handleRows(batchPrices);
  return price;
};

const handleDatePrice = async (
  order: Order,
  customPrice = false,
  customListPrice = 0,
  orderBatches: OrderBatch[],
  mediaDiscount: boolean,
  floatingOrder?: {
    floating: boolean;
    quantity: number;
    variations: string[];
    floatingAmount: number;
    batchName: string;
  }
): Promise<MultipressCalculatedPrice> => {
  if (floatingOrder && floatingOrder.floating) {
    if (customPrice) {
      const price = await productClient.calculateFromSolidPrice(
        {
          ...order,
          rowName: floatingOrder.batchName,
          units: floatingOrder.floatingAmount,
          variations: floatingOrder.variations.length,
          quantity: floatingOrder.quantity,
          mediaDiscount,
        },
        customListPrice
      );
      price.priceRows = [
        price.priceRows.reduce((result, row) => {
          return {
            basePrice: result.basePrice + row.basePrice,
            discountPercentage: row.discountPercentage,
            productId: row.productId,
            productName: row.productName,
            quantity: row.quantity,
            unitPrice: row.unitPrice + result.unitPrice,
          };
        }),
      ];
      return price;
    }

    const price = await productClient.calculateMultipressPrice({
      ...order,
      rowName: floatingOrder.batchName,
      units: floatingOrder.floatingAmount,
      variations: floatingOrder.variations.length,
      quantity: floatingOrder.quantity,
      mediaDiscount,
    });

    price.priceRows = [
      price.priceRows.reduce((result, row) => {
        return {
          basePrice: result.basePrice + row.basePrice,
          discountPercentage: row.discountPercentage,
          productId: row.productId,
          productName: row.productName,
          quantity: row.quantity,
          unitPrice: row.unitPrice + result.unitPrice,
        };
      }),
    ];
    return price;
  }

  //undefined kun batches ei oo kunnossa
  const handledBatches = handleBatches(orderBatches);

  if (customPrice) {
    const batchValues = handledBatches.reduce((value: number, current: Batch) => {
      return value + current.quantity * current.dates * current.variations;
    }, 0);
    // tassa jaetaan tuo nuiden batsien kesken
    handledBatches.forEach((batch) => {
      batch.percentage = (batch.quantity * batch.dates * batch.variations) / batchValues;
    });

    const promises = handledBatches.map(async (batch) => {
      const price = await productClient.calculateFromSolidPrice(
        {
          ...order,
          discountValue: order.isPercentage
            ? order.discountValue
            : order.discountValue / handledBatches.length,
          rowName: batch.name || '',
          units: batch.dates,
          variations: batch.variations,
          quantity: batch.quantity,
          mediaDiscount,
        },
        Math.round((customListPrice * (batch.percentage || 1) + Number.EPSILON) * 100) / 100
      );
      return { batch, price };
    });

    const prices: BatchPrice[] = await Promise.all(promises);
    /// rakennetaan hinta objekti

    const price = reduceBatchPrices(prices, customPrice);
    const rows = handleRows(prices);
    price.priceRows = rows;
    return price;
  }

  const batchMultipressPrice = handledBatches.map(async (batch) => {
    const price = await productClient.calculateMultipressPrice({
      ...order,
      discountValue: order.isPercentage
        ? order.discountValue
        : order.discountValue / handledBatches.length,
      rowName: batch.name || '',
      units: batch.dates,
      variations: batch.variations,
      quantity: batch.quantity,
      mediaDiscount,
    });
    return { batch, price };
  });

  const batchPrices = await Promise.all(batchMultipressPrice);
  const price = reduceBatchPrices(batchPrices, customPrice);
  price.priceRows = handleRows(batchPrices);

  return price;
};

const calculatePrice = async (
  order: Order,
  customPrice = false,
  customListPrice = 0,
  orderBatches: OrderBatch[],
  limitType = 'second',
  mediaDiscount = false,
  tapePrice = 0,
  floatingOrder?: {
    floating: boolean;
    quantity: number;
    variations: string[];
    floatingAmount: number;
    batchName: string;
  }
): Promise<MultipressCalculatedPrice> => {
  if (limitType == 'week')
    return handleWeekPrice(
      order,
      customPrice,
      customListPrice,
      orderBatches,
      mediaDiscount,
      floatingOrder
    );
  if (limitType == 'month')
    return handleMonthPrice(
      order,
      customPrice,
      customListPrice,
      orderBatches,
      mediaDiscount,
      floatingOrder,
      tapePrice
    );

  return handleDatePrice(
    order,
    customPrice,
    customListPrice,
    orderBatches,
    mediaDiscount,
    floatingOrder
  );
};

export default calculatePrice;
