import { navigate } from '@reach/router';
import { toast } from 'react-toastify';
import {
  AddOrderState,
  CustomerCampaign,
  MultipressCalculatedPrice,
  OrderDetails,
  OrderSummary,
} from '../../interfaces';
import { CampaignInput } from '../../types/campaign-input';
import { EmailContext } from '../../types/email-context';
import { OrderBatch } from '../../types/order-batch';
import { checkSession, setSession } from '../../utils/session-utils';
import { toastOptions } from '../../utils/toast-options';
import CampaignClient from '../campaign-client';
import reservationClient from '../reservation-client';
import addCampaignPrice from './add-campaign-price';
import addMonthReservations from './add-month-reservations';
import addRoughReservations from './add-rough-reservations';
import addWeekReservations from './add-week-reservations';
import downloadOfferPdf from './download-offer-pdf';

const addCampaignOffer = async (
  orderDetails: OrderDetails,
  campaign: CustomerCampaign,
  orderSummary: OrderSummary,
  price: MultipressCalculatedPrice,
  setSending: Function,
  batches: OrderBatch[],
  addOrderState: AddOrderState,
  attachments: { filename: string; path: string }[],
  offerPdf = false,
  email?: EmailContext
): Promise<void> => {
  const { floatingOrder } = addOrderState;
  const campaignData: CampaignInput = {
    ...campaign,
    campaignName: campaign.name,
    customer: campaign.customerId,
    description: '',
    offer: true,
    campaignDetails: orderDetails.campaignDetails,
    ...orderSummary,
    attachments,
    floating: floatingOrder ? floatingOrder.floating : false,
    floatingAmount: floatingOrder ? floatingOrder.floatingAmount : 1,
    floatingQuantity: floatingOrder ? floatingOrder.quantity : 5,
    floatingVariations: floatingOrder ? floatingOrder.variations.join(',') : '',
    floatingBatchName: floatingOrder ? floatingOrder.batchName : '',
    offerPdf,
    email,
  };

  let campaignId = null;

  try {
    campaignId = await CampaignClient.addCampaign(campaignData as CampaignInput);
    toast.success('Kampanja lisätty', toastOptions);
  } catch (error) {
    setSending(false);
    toast.error('Kampanjan lisäys epäonnistui', toastOptions);

    return;
  }

  try {
    if (addOrderState.limitType === 'month') {
      await addMonthReservations({
        product: addOrderState.productId,
        batches,
        campaign: campaignId,
        rough: true,
      });
    }
    if (addOrderState.limitType === 'week') {
      await addWeekReservations({
        product: addOrderState.productId,
        batches,
        campaign: campaignId,
        rough: true,
      });
    } else {
      await addRoughReservations({
        product: addOrderState.productId,
        campaign: campaignId,
        batches,
      });
    }
    toast.success('Alustavat varaukset lisätty', toastOptions);
  } catch (error) {
    toast.error('Alustavien varauksien lisäys epäonnistui', toastOptions);
    setSending(false);

    await CampaignClient.removeCampaign(campaignId);

    return;
  }

  try {
    await addCampaignPrice({
      ...price,
      campaign: campaignId,
      discountAmount: orderSummary.discountAmount,
      discountPercent: orderSummary.discountPercent,
      tapePrice: addOrderState.tapePrice,
    });

    toast.success('Hinnat lisätty', toastOptions);
  } catch (error) {
    toast.error('Hintojen lisäys epäonnistui', toastOptions);
    setSending(false);

    await reservationClient.removeReservationsByCampaingId(campaignId);
    await CampaignClient.removeCampaign(campaignId);

    return;
  }

  toast.info('Varauksien vahvistuksessa saattaa kestää 1-2min, odotathan.', toastOptions);

  try {
    if (floatingOrder?.floating) await CampaignClient.checkFloatingCampaignStatus(campaignId);
  } catch {
    //pass
  }
  if (offerPdf) {
    if (checkSession()) setSession();
    else navigate('/');
    await downloadOfferPdf(campaignId);
  }
  setSending(false);
  navigate('/offers');
};

export default addCampaignOffer;
