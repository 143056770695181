import { OrderBatch } from '../../types/order-batch';
import { Order } from './calculate-price';

export const calculateCustomPrice = (
  order: Order,
  customPrice: number,
  campaign: string,
  batches: OrderBatch[],
  productName: string,
  tapePrice = 0
) => {
  // some logic
  let totalPrice = customPrice + tapePrice;

  const discount = order.discountValue;
  const discountPercent = order.isPercentage ? discount : 0;
  const discountAmount = order.isPercentage ? 0 : order.discountValue;

  if (discount) {
    if (discountPercent) {
      totalPrice = totalPrice - (totalPrice * discountPercent) / 100;
    }
    if (discountAmount) {
      totalPrice = totalPrice - discountAmount;
    }
  }

  const rowPrice = Math.round((customPrice / batches.length + Number.EPSILON) * 100) / 100;

  const priceRows = batches.map((batch) => {
    return {
      productId: productName,
      productName: batch.name || '',
      quantity: batch.quantity,
      unitPrice: rowPrice,
      basePrice: 0,
      discountPercentage: discountPercent,
      customName: batch.customName,
      batch: batch.id.toString(),
    };
  });
  priceRows.push({
    productId: productName,
    productName: 'Tuotanto',
    quantity: 1,
    unitPrice: tapePrice,
    basePrice: 0,
    discountPercentage: discountPercent,
    customName: false,
    batch: '',
  });

  const trueDiscountValue =
    discount && discountPercent
      ? Math.round((customPrice + tapePrice) * discountPercent + Number.EPSILON) / 100
      : discountAmount;

  return {
    campaign: campaign,
    mediaDiscount: 0,
    totalPrice:
      Math.round((totalPrice + totalPrice * (order.vat / 100) + Number.EPSILON) * 100) / 100,
    vat: Math.round((totalPrice * (order.vat / 100) + Number.EPSILON) * 100) / 100,
    discount: trueDiscountValue,
    listPrice: customPrice,
    priceRows: priceRows,
    solid: true,
    discountPercent: discountPercent
      ? Math.round((discountPercent + Number.EPSILON) * 100) / 100
      : 0,
    discountAmount: discountAmount,
  };
};
