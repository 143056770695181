import React, { createContext, ReactNode, useReducer } from 'react';
import { CampaignInterface } from '../interfaces';

type State = {
  productId: string;
  productName: string;
  customerDiscount: number;
  limitType: string;
  customerBusinessId: string;
  customerId?: string;
  customPrice: boolean;
  customListPrice: number;
  floatingOrderError?: string;
  noOffset: boolean;
  initialized: boolean;
  tapePrice: number;
  floatingOrder?: {
    floating: boolean;
    quantity: number;
    variations: string[];
    floatingAmount: number;
    batchName: string;
  };
};

type Action =
  | { type: 'update'; payload: Partial<State> }
  | { type: 'initByOrder'; payload: CampaignInterface };
type Dispatch = (action: Action) => void;

const AddOrderContext = createContext<State | undefined>(undefined);
const AddOrderDispatchContext = createContext<Dispatch | undefined>(undefined);

const handlePayload = (payloadO: Partial<State>, state: State): Partial<State> => {
  const payload = { ...payloadO };

  // if (payload.productId && state.productId !== '') {
  //   payload.isMultiMonth = false;
  // }

  // if (
  //   payloadO.isMultiMonth !== undefined &&
  //   state.isMultiMonth !== payloadO.isMultiMonth &&
  //   payloadO.advancePayment === undefined &&
  //   !state.initialized
  // ) {
  //   payloadO.advancePayment = !payloadO.isMultiMonth;
  // }
  return payloadO;
};

const initByOrder = (payload: CampaignInterface): Partial<State> => {
  const {
    campaignPrice,
    customer,
    advancePayment,
    reservations = [],
    floating,
    floatingAmount,
    floatingQuantity,
    floatingBatchName,
    floatingVariations,
  } = payload;
  const floatingOrder = {
    floating: !!floating,
    quantity: floatingQuantity || 5,
    variations: floatingVariations ? floatingVariations.split(',') : [],
    batchName: floatingBatchName || '',
    floatingAmount: floatingAmount || 1,
  };

  const { solid, listPrice, tapePrice } = campaignPrice;

  return {
    productId: reservations && reservations.length > 0 ? reservations[0].productId : '',
    customerBusinessId: customer ? customer.businessid : '',
    customerId: customer ? customer.companyId : '',
    floatingOrder,
    customPrice: solid,
    customListPrice: listPrice,
    initialized: true,
    tapePrice: tapePrice || 0,
  };
};

const addOrderReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'update':
      return { ...state, ...handlePayload(action.payload, state) };
    case 'initByOrder':
      return { ...state, ...initByOrder(action.payload) };
    default: {
      throw new Error('AddOrderContext error');
    }
  }
};

interface Props {
  children: ReactNode;
  initialValues?: State;
}

const AddOrderProvider: React.FC<Props> = ({ children, initialValues }: Props) => {
  const initialState = initialValues || {
    productId: '',
    invoiceDiscount: 0,
    limitType: '',
    productName: '',
    customerBusinessId: '',
    customerDiscount: 0,
    tapePrice: 0,
    customPrice: false,
    customListPrice: 0,
    orderBatches: [],
    noOffset: false,
    initialized: false,
  };

  const [state, dispatch] = useReducer(addOrderReducer, {
    ...initialState,
  });

  return (
    <AddOrderContext.Provider value={state}>
      <AddOrderDispatchContext.Provider value={dispatch}>
        {children}
      </AddOrderDispatchContext.Provider>
    </AddOrderContext.Provider>
  );
};

const useAddOrderState = (): State => {
  const context = React.useContext(AddOrderContext);
  if (context === undefined) throw new Error('Use AddOrderProvider');
  return context;
};

const useAddOrderDispatch = (): Dispatch => {
  const context = React.useContext(AddOrderDispatchContext);
  if (context === undefined) throw new Error('Use AddOrderProvider');
  return context;
};

export { AddOrderProvider, useAddOrderState, useAddOrderDispatch };
