import {
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useAddOrderDispatch, useAddOrderState } from '../../context/add-order-context';
import { useOrderFormState } from '../../context/order-form-context';

const useStyles = makeStyles(() => ({
  inputContainer: {
    justifyContent: 'space-between',
  },
  rightItem: {
    width: '400px',
  },
  boldText: {
    fontWeight: 'bold',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: 'auto 0px',
  },
  blueText: { fontSize: '16px', color: '#5E8EFC' },
}));

const SummaryListprice: React.FC = () => {
  const classes = useStyles();
  const addOrder = useAddOrderState();
  const addOrderDispatch = useAddOrderDispatch();

  const orderFormState = useOrderFormState();

  const { price } = orderFormState;
  const { customPrice, customListPrice } = addOrder;

  const addListprice = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    addOrderDispatch({ type: 'update', payload: { customListPrice: +value } });
  };

  useEffect(() => {
    if (addOrder.limitType === 'custom' && !customPrice) {
      addOrderDispatch({
        type: 'update',
        payload: { customPrice: true },
      });
    }
  }, [addOrder.limitType]);

  return (
    <Grid
      container
      className={classes.inputContainer}
      alignItems="flex-end"
      item
      style={{ marginBottom: '35px' }}
    >
      <Grid item>
        <Typography className={classes.boldText}>
          {addOrder.limitType === 'custom' ? 'Media' : 'OVH Yhteensä'}
        </Typography>
      </Grid>
      <Grid item container direction="row" className={classes.rightItem}>
        {customPrice ? (
          <TextField
            name="invoiceDiscount"
            onChange={addListprice}
            value={customListPrice}
            onFocus={(event): void => event.target.select()}
            type="number"
            inputProps={{
              min: 0.0,
            }}
            InputProps={{ className: classes.title }}
          ></TextField>
        ) : (
          <Typography className={`${classes.boldText} ${classes.title}`}>
            {price ? price.listPrice.toFixed(2) : 0} €
          </Typography>
        )}

        {addOrder.limitType !== 'custom' && (
          <FormControl>
            <FormControlLabel
              labelPlacement="end"
              style={{ marginLeft: '10px' }}
              classes={{ label: classes.blueText }}
              label="Anna kiinteä OVH"
              control={
                <Switch
                  checked={customPrice}
                  onChange={(): void => {
                    addOrderDispatch({
                      type: 'update',
                      payload: { customPrice: !customPrice },
                    });
                  }}
                  color="primary"
                ></Switch>
              }
            ></FormControlLabel>
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
};

export default SummaryListprice;
